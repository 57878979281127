const Cities = [
  {
    id: 0,
    name: "Adana",
    code: null,
    icon: null,
  },
  {
    id: 1,
    name: "Adıyaman",
    code: null,
    icon: null,
  },
  {
    id: 2,
    name: "Afyonkarahisar",
    code: null,
    icon: null,
  },
  {
    id: 3,
    name: "Ağrı",
    code: null,
    icon: null,
  },
  {
    id: 4,
    name: "Amasya",
    code: null,
    icon: null,
  },
  {
    id: 5,
    name: "Ankara",
    code: null,
    icon: null,
  },
  {
    id: 6,
    name: "Antalya",
    code: null,
    icon: null,
  },
  {
    id: 7,
    name: "Artvin",
    code: null,
    icon: null,
  },
  {
    id: 8,
    name: "Aydın",
    code: null,
    icon: null,
  },
  {
    id: 9,
    name: "Balıkesir",
    code: null,
    icon: null,
  },
  {
    id: 10,
    name: "Bilecik",
    code: null,
    icon: null,
  },
  {
    id: 11,
    name: "Bingöl",
    code: null,
    icon: null,
  },
  {
    id: 12,
    name: "Bitlis",
    code: null,
    icon: null,
  },
  {
    id: 13,
    name: "Bolu",
    code: null,
    icon: null,
  },
  {
    id: 14,
    name: "Burdur",
    code: null,
    icon: null,
  },
  {
    id: 15,
    name: "Bursa",
    code: null,
    icon: null,
  },
  {
    id: 16,
    name: "Çanakkale",
    code: null,
    icon: null,
  },
  {
    id: 17,
    name: "Çankırı",
    code: null,
    icon: null,
  },
  {
    id: 18,
    name: "Çorum",
    code: null,
    icon: null,
  },
  {
    id: 19,
    name: "Denizli",
    code: null,
    icon: null,
  },
  {
    id: 20,
    name: "Diyarbakır",
    code: null,
    icon: null,
  },
  {
    id: 21,
    name: "Edirne",
    code: null,
    icon: null,
  },
  {
    id: 22,
    name: "Elâzığ",
    code: null,
    icon: null,
  },
  {
    id: 23,
    name: "Erzincan",
    code: null,
    icon: null,
  },
  {
    id: 24,
    name: "Erzurum",
    code: null,
    icon: null,
  },
  {
    id: 25,
    name: "Eskişehir",
    code: null,
    icon: null,
  },
  {
    id: 26,
    name: "Gaziantep",
    code: null,
    icon: null,
  },
  {
    id: 27,
    name: "Giresun",
    code: null,
    icon: null,
  },
  {
    id: 28,
    name: "Gümüşhane",
    code: null,
    icon: null,
  },
  {
    id: 29,
    name: "Hakkâri",
    code: null,
    icon: null,
  },
  {
    id: 30,
    name: "Hatay",
    code: null,
    icon: null,
  },
  {
    id: 31,
    name: "Isparta",
    code: null,
    icon: null,
  },
  {
    id: 32,
    name: "Mersin",
    code: null,
    icon: null,
  },
  {
    id: 33,
    name: "İstanbul",
    code: null,
    icon: null,
  },
  {
    id: 34,
    name: "İzmir",
    code: null,
    icon: null,
  },
  {
    id: 35,
    name: "Kars",
    code: null,
    icon: null,
  },
  {
    id: 36,
    name: "Kastamonu",
    code: null,
    icon: null,
  },
  {
    id: 37,
    name: "Kayseri",
    code: null,
    icon: null,
  },
  {
    id: 38,
    name: "Kırklareli",
    code: null,
    icon: null,
  },
  {
    id: 39,
    name: "Kırşehir",
    code: null,
    icon: null,
  },
  {
    id: 40,
    name: "Kocaeli",
    code: null,
    icon: null,
  },
  {
    id: 41,
    name: "Konya",
    code: null,
    icon: null,
  },
  {
    id: 42,
    name: "Kütahya",
    code: null,
    icon: null,
  },
  {
    id: 43,
    name: "Malatya",
    code: null,
    icon: null,
  },
  {
    id: 44,
    name: "Manisa",
    code: null,
    icon: null,
  },
  {
    id: 45,
    name: "Kahramanmaraş",
    code: null,
    icon: null,
  },
  {
    id: 46,
    name: "Mardin",
    code: null,
    icon: null,
  },
  {
    id: 47,
    name: "Muğla",
    code: null,
    icon: null,
  },
  {
    id: 48,
    name: "Muş",
    code: null,
    icon: null,
  },
  {
    id: 49,
    name: "Nevşehir",
    code: null,
    icon: null,
  },
  {
    id: 50,
    name: "Niğde",
    code: null,
    icon: null,
  },
  {
    id: 51,
    name: "Ordu",
    code: null,
    icon: null,
  },
  {
    id: 52,
    name: "Rize",
    code: null,
    icon: null,
  },
  {
    id: 53,
    name: "Sakarya",
    code: null,
    icon: null,
  },
  {
    id: 54,
    name: "Samsun",
    code: null,
    icon: null,
  },
  {
    id: 55,
    name: "Siirt",
    code: null,
    icon: null,
  },
  {
    id: 56,
    name: "Sinop",
    code: null,
    icon: null,
  },
  {
    id: 57,
    name: "Sivas",
    code: null,
    icon: null,
  },
  {
    id: 58,
    name: "Tekirdağ",
    code: null,
    icon: null,
  },
  {
    id: 59,
    name: "Tokat",
    code: null,
    icon: null,
  },
  {
    id: 60,
    name: "Trabzon",
    code: null,
    icon: null,
  },
  {
    id: 61,
    name: "Tunceli",
    code: null,
    icon: null,
  },
  {
    id: 62,
    name: "Şanlıurfa",
    code: null,
    icon: null,
  },
  {
    id: 63,
    name: "Uşak",
    code: null,
    icon: null,
  },
  {
    id: 64,
    name: "Van",
    code: null,
    icon: null,
  },
  {
    id: 65,
    name: "Yozgat",
    code: null,
    icon: null,
  },
  {
    id: 66,
    name: "Zonguldak",
    code: null,
    icon: null,
  },
  {
    id: 67,
    name: "Aksaray",
    code: null,
    icon: null,
  },
  {
    id: 68,
    name: "Bayburt",
    code: null,
    icon: null,
  },
  {
    id: 69,
    name: "Karaman",
    code: null,
    icon: null,
  },
  {
    id: 70,
    name: "Kırıkkale",
    code: null,
    icon: null,
  },
  {
    id: 71,
    name: "Batman",
    code: null,
    icon: null,
  },
  {
    id: 72,
    name: "Şırnak",
    code: null,
    icon: null,
  },
  {
    id: 73,
    name: "Bartın",
    code: null,
    icon: null,
  },
  {
    id: 74,
    name: "Ardahan",
    code: null,
    icon: null,
  },
  {
    id: 75,
    name: "Iğdır",
    code: null,
    icon: null,
  },
  {
    id: 76,
    name: "Yalova",
    code: null,
    icon: null,
  },
  {
    id: 77,
    name: "Karabük",
    code: null,
    icon: null,
  },
  {
    id: 78,
    name: "Kilis",
    code: null,
    icon: null,
  },
  {
    id: 79,
    name: "Osmaniye",
    code: null,
    icon: null,
  },
  {
    id: 80,
    name: "Düzce",
    code: null,
    icon: null,
  },
];

export default Cities;
